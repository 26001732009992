import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'
import { getShamaaziEnvironmentFromURL, initSentryInBrowser, ShamaaziEnvironment } from '@lib/services'

// Figure out environment from URL
const environment: ShamaaziEnvironment | undefined = getShamaaziEnvironmentFromURL()

// Prevent search engine spiders from crawling the website
if (environment !== ShamaaziEnvironment.production) {
  const meta = document.createElement('meta')
  meta.name = 'robots'
  meta.content = 'noindex'
  document.querySelector('head')?.appendChild(meta)
}

// Initialize Sentry (crash analytics)
const dsn: string = 'https://3441bd4283be4e4761a64757a1a401a5@o4505828020125696.ingest.sentry.io/4505828023468032'
// - control which URLs distributed tracing should be enabled for
const tracePropagationTargetsStaging = [/^https:\/\/.+\.admin\.shamaazi\.io\/api/] // private envs & staging
const tracePropagationTargetsProduction = ['https://admin.shamaazi.com/api']
initSentryInBrowser(environment, dsn, tracePropagationTargetsStaging, tracePropagationTargetsProduction)

// Render React App
ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
